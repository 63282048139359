<template>
    <div>
        <!-- Personal Information -->
        <validation-observer ref="personalInfoForm">
        <b-card>
            <!-- form -->
            <b-form>
                <b-row>
                    <!-- Category Name -->
                    <b-col md="3">
                        <b-form-group label="Category Name">
                        <validation-provider
                            #default="{ errors }"
                            name="category name"
                            rules="required"
                        >
                            <b-form-input
                                v-model="categoryName"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Enter Text"
                                @blur="categoryName=categoryName.trim()"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!-- Manage By Student -->
                    <b-col md="4">
                        <b-form-group label="Manage By Student">
                        <validation-provider
                            #default="{ errors }"
                            name="manage by student"
                            rules="required"
                        >
                            <div class="demo-inline-spacing" style="height:35px">
                                <b-form-radio
                                v-model="manageByStudent"
                                plain
                                name="manage by student"
                                :value="true"
                                >
                                Yes
                                </b-form-radio>
                                <b-form-radio
                                v-model="manageByStudent"
                                plain
                                name="manage by student"
                                :value="false"
                                >
                                No
                                </b-form-radio>
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>


        <b-card>
            <!-- form -->
            <b-form>
                <b-row v-for="(data,index) in documents" :key="index" style="border-bottom:1px solid #cfcfcf;">
                    <!-- Document Name -->
                    <b-col md="3">
                        <b-form-group label="Document Name">
                        <validation-provider
                            #default="{ errors }"
                            name="document name"
                            rules="required"
                            :vid="`document name`+index"
                        >
                            <b-form-input
                                :name="`document`+index"
                                v-model="data.documentName"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Enter Text"
                                @blur="data.documentName=data.documentName.trim()"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!-- Upload File Type -->
                    <b-col md="3">
                        <b-form-group label="Upload File Type">
                        <validation-provider
                            #default="{ errors }"
                            name="upload file type"
                            rules="required"
                            :vid="'checkbox-group-2'+index"
                        >
                            <b-form-checkbox-group
                                :name="'checkbox-group-2'+index"
                                v-model="data.uploadFileType"
                                :options="uploadFileTypeOptions"
                                class="demo-inline-spacing"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Upload Demo File -->
                    <b-col md="2">
                        <b-form-group label="Upload Demo File">
                        <validation-provider
                            #default="{ errors }"
                            name="upload demo file"
                            :rules="Object.keys(data.image).length>1?'':'required|ext:jpg|size:2048'"
                            :vid="`upload demo file`+index"
                        >
                            <b-form-file
                                :id="`upload demo file`+index"
                                name="upload demo file"
                                v-model="data.image"
                            />
                            <small class="text-danger" v-if="data.isImage">{{ errors[0] }}</small>
                            <small class="text-danger" v-else>Please select an image file.</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!-- Delete Button -->
                    <b-col md="4" style="margin: auto;text-align: right;">
                        <b-form-group>
                            <b-button
                                variant="outline-danger"
                                class="btn-icon"
                                style="height:inherit"
                                @click.prevent="removeRow(index)"
                            >
                            X Delete
                            </b-button>
                        </b-form-group>
                    </b-col>
                </b-row>
                <a @click.prevent="addNewRow()">+ Add New</a>
            </b-form>
        </b-card>

        </validation-observer>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCardText,BFormSelect,BFormDatepicker,BFormRadio,BFormFile,
  BFormTextarea,BFormCheckboxGroup,
} from 'bootstrap-vue'
import firebase from "@/utils/firebaseInit.js";
import firebaseTimeStamp from "firebase";
import { extend } from 'vee-validate';
import { required, email,max,max_value,integer,numeric,min,min_value,alpha_spaces,double,ext,size } from 'vee-validate/dist/rules';

extend('required',required)
extend('ext',ext)
extend('size',size)
extend('max',max)
extend('min',min)
extend('min_value',min_value)
extend('email',email)
extend('alpha_spaces',alpha_spaces)
extend('max_value',max_value)
extend('integer',integer)
extend('numeric',numeric)
extend('double',double)

import { dbCollections } from "@/utils/firebaseCollection.js";
import { uploadImageFile } from '@/utils/FirebaseQueries/storageQuery/storageQueries'
import { ValidationFunction } from '@/utils/globalValidations.js';

const db = firebase.firestore()

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BCard,
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormSelect,
        BFormDatepicker,
        BFormRadio,
        BFormFile,
        BFormTextarea,
        BFormCheckboxGroup,
    },
    data() {
        return {
            manageByStudent:false,
            uploadFileTypeOptions: [
                { value: 'Front', text: 'Front' },
                { value: 'Back', text: 'Back' },
            ],
            imgFile:[],
            imageFile:[],
            previewImage:[],

            categoryName: '',
            documents:[],

            allDocs:[],

            //Firebase storage Folder name to store files
            folderName:'Document Images',

            docId:this.$route.params.id,
        }
    },
    created() {
        var self = this

        self.$root.$on('saveEditedDoc',()=>{
            console.log('save called')
            if(self.$refs.personalInfoForm)
            {
                self.saveNewCategory()
            }
        })
        self.$root.$on('cancelEditedDoc',()=>{
            console.log('close called')
            if(self.$refs.personalInfoForm)
            {
                self.$refs.personalInfoForm.reset()
                self.$router.push({name:'doc-category-mgmt'})
            }
        })

        console.log("DocId",self.docId)

        db
            .collection(dbCollections.DOCUMENTCATEGORY)
            .doc(self.docId)
            .get()
            .then((doc)=>{
                self.categoryName=doc.data().categoryName
                self.manageByStudent=doc.data().manageByStudent
                self.allDocs=doc.data().documents

                self.allDocs.forEach(data=>{
                    self.documents.push({
                        documentName:data.documentName,
                        uploadFileType:data.uploadFileType,
                        image:data.image,
                        isImage:data.isImage
                    })
                })
            })
            .catch(error=>{
                console.log(error)
            })
    },
    methods: {
        //Check if is valid(image) file
        checkFile(e,index)
        {
            var files = e.target.files || e.dataTransfer.files;
            this.documents[index].isImage=true
            if(files.length!=0)
            {
                this.previewImage=[]
                this.imageFile=[]
            }
            // this.attachmentImages=[]
            // this.attachments=[]
            files.forEach(data=>{
                if(data.type.split('/')[0]!='image')
                {
                    this.documents[index].isImage=false
                    // this.attachmentImages=[]
                }
            })
            if(this.documents[index].isImage){
                this.documents[index].isImage=true
                this.documents[index].image=files[0];
                // this.createImage(files)
                // console.log(this.attachments)
            }
            else
            { 
                this.documents[index].image=[]
                // document.getElementById("imagePicker").value=""
            }
        },
        createImage(files) {
            //   var image = new Image();
            
            files.forEach(data=>{
                var vm = this;
                var reader = new FileReader();
    
                reader.onload = (e) => {
                    vm.previewImage.push(e.target.result);
                    vm.imageFile.push(data)
                    console.log("images")
                    console.log(vm.previewImage)
                    console.log(vm.imageFile)
                }
                reader.readAsDataURL(data);
            })
        },
        addNewRow(){
            //add field
            this.documents.push({
                documentName:'',
                uploadFileType:[],
                image:[],
                isImage:true
            })
        },
        removeRow(index)
        {
            //remove field
            this.documents.splice(index, 1)
        },
        saveNewCategory() {
            var self = this
            console.log("Validate",self.$refs.personalInfoForm)
            self.$refs.personalInfoForm.validate().then(success => {
                if (success) {
                    var docsArr=[]
                    self.documents.forEach(imgs=>{
                        console.log('imgs',imgs.image)
                        if(Object.keys(imgs.image).length<2)
                        {
                            uploadImageFile(self.folderName,imgs.image.name,imgs.image,res=>{
                                docsArr.push({
                                    documentName:imgs.documentName,
                                    uploadFileType:imgs.uploadFileType,
                                    image:res,
                                    isImage:imgs.isImage
                                })
                                if(docsArr.length == self.documents.length)
                                {
                                    console.log("Success",self.categoryName,self.manageByStudent,self.documents)
                                    db
                                        .collection(dbCollections.DOCUMENTCATEGORY)
                                        .doc(self.docId)
                                        .update({
                                            updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                            isDeleted:false,
                                            categoryName:self.categoryName,
                                            manageByStudent:self.manageByStudent,
                                            documents:docsArr
                                        })
                                        .then(()=>{
                                            self.$root.$emit('stopSpinner')
                                            self.$router.push({name:'doc-category-mgmt'})
                                        })
                                        .catch(error=>{
                                            console.log(error)
                                            self.$root.$emit('stopSpinner')
                                        })
                                }
                            })
                        }
                        else
                        {
                            docsArr.push(imgs)
                            if(docsArr.length == self.documents.length)
                            {
                                console.log("Success",self.categoryName,self.manageByStudent,self.documents)
                                db
                                    .collection(dbCollections.DOCUMENTCATEGORY)
                                    .doc(self.docId)
                                    .update({
                                        updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                        isDeleted:false,
                                        categoryName:self.categoryName,
                                        manageByStudent:self.manageByStudent,
                                        documents:docsArr
                                    })
                                    .then(()=>{
                                        self.$root.$emit('stopSpinner')
                                        self.$router.push({name:'doc-category-mgmt'})
                                    })
                                    .catch(error=>{
                                        console.log(error)
                                        self.$root.$emit('stopSpinner')
                                    })
                            }
                        }
                    })
                }
                else
                {
                    console.log("Error",success)
                    self.$root.$emit('stopSpinner')
                }
            })
        },
    },
}
</script>