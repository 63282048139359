var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"personalInfoForm"},[_c('b-card',[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Category Name"}},[_c('validation-provider',{attrs:{"name":"category name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Text"},on:{"blur":function($event){_vm.categoryName=_vm.categoryName.trim()}},model:{value:(_vm.categoryName),callback:function ($$v) {_vm.categoryName=$$v},expression:"categoryName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Manage By Student"}},[_c('validation-provider',{attrs:{"name":"manage by student","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"demo-inline-spacing",staticStyle:{"height":"35px"}},[_c('b-form-radio',{attrs:{"plain":"","name":"manage by student","value":true},model:{value:(_vm.manageByStudent),callback:function ($$v) {_vm.manageByStudent=$$v},expression:"manageByStudent"}},[_vm._v(" Yes ")]),_c('b-form-radio',{attrs:{"plain":"","name":"manage by student","value":false},model:{value:(_vm.manageByStudent),callback:function ($$v) {_vm.manageByStudent=$$v},expression:"manageByStudent"}},[_vm._v(" No ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-card',[_c('b-form',[_vm._l((_vm.documents),function(data,index){return _c('b-row',{key:index,staticStyle:{"border-bottom":"1px solid #cfcfcf"}},[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Document Name"}},[_c('validation-provider',{attrs:{"name":"document name","rules":"required","vid":"document name"+index},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"document"+index,"state":errors.length > 0 ? false:null,"placeholder":"Enter Text"},on:{"blur":function($event){data.documentName=data.documentName.trim()}},model:{value:(data.documentName),callback:function ($$v) {_vm.$set(data, "documentName", $$v)},expression:"data.documentName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Upload File Type"}},[_c('validation-provider',{attrs:{"name":"upload file type","rules":"required","vid":'checkbox-group-2'+index},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{staticClass:"demo-inline-spacing",attrs:{"name":'checkbox-group-2'+index,"options":_vm.uploadFileTypeOptions},model:{value:(data.uploadFileType),callback:function ($$v) {_vm.$set(data, "uploadFileType", $$v)},expression:"data.uploadFileType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Upload Demo File"}},[_c('validation-provider',{attrs:{"name":"upload demo file","rules":Object.keys(data.image).length>1?'':'required|ext:jpg|size:2048',"vid":"upload demo file"+index},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"upload demo file"+index,"name":"upload demo file"},model:{value:(data.image),callback:function ($$v) {_vm.$set(data, "image", $$v)},expression:"data.image"}}),(data.isImage)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_c('small',{staticClass:"text-danger"},[_vm._v("Please select an image file.")])]}}],null,true)})],1)],1),_c('b-col',{staticStyle:{"margin":"auto","text-align":"right"},attrs:{"md":"4"}},[_c('b-form-group',[_c('b-button',{staticClass:"btn-icon",staticStyle:{"height":"inherit"},attrs:{"variant":"outline-danger"},on:{"click":function($event){$event.preventDefault();return _vm.removeRow(index)}}},[_vm._v(" X Delete ")])],1)],1)],1)}),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.addNewRow()}}},[_vm._v("+ Add New")])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }